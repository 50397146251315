/* .wpwl-group-cardHolder {
  display: flex;
  flex-direction: column;
} */

.main-content {
  /* padding-top: 5em; */

  /* border: 1px solid red; */
}

.wpwl-form-registrations {
  width: 100%;
  background-color: transparent;
  margin-bottom: 0.5em;
}

/* .wpwl-form-registrations::after{
  content: "";
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #d8d6de;
  position: absolute;
  bottom: 2em;
} */

.wpwl-group-registration {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  /* padding: 0.438rem 1rem; */
  padding: 0.5rem 1rem;
  position: relative;
}

.wpwl-group-registration.wpwl-selected {
  border: 2px solid #7367f0;
}

.wpwl-registration {
  /* border: 1px solid red; */
  display: grid;
  /* grid-template-columns: 5% 20% 45% 30%; */
  grid-template-columns: 20% 55% 25%;
  align-items: center;
}

.wpwl-wrapper-registration {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  float: none;
}

.wpwl-wrapper-registration-registrationId {
  display: none;
}

.wpwl-wrapper-registration-brand {
  /* border: 1px solid red; */
  display: grid;
  place-items: center;
  width: 100%;
}

.wpwl-brand {
  /* border: 1px solid orange; */
}

.wpwl-wrapper-registration-details {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0.5em;
}

.wpwl-wrapper-registration-number{
  justify-content: left;
}

.wpwl-wrapper-registration-holder {
  grid-column: 1 / span 2;
  display: flex;
  justify-content: left;
  font-size: 13px;
  font-weight: 500;
}

.wpwl-wrapper-registration-cvv {
  /* border: 1px solid green; */
  /* margin-top: 1em; */
  border-radius: 0.357rem;
}

.wpwl-group-registration.wpwl-selected .wpwl-wrapper-registration-cvv {
  /* border: 1px solid #7367f0; */
}

.wpwl-container {
  /* border: 1px solid purple; */
}

.wpwl-form {
  /* border: 1px solid green; */
}

.wpwl-form-card {
  /* border: 1px solid green; */
  background-color: #fff;
}

.wpwl-group-brand {
  /* border: 1px solid red; */
  flex-direction: row;
}

.wpwl-label {
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #5e5873;
  display: none !important;
}

.wpwl-control-cardHolder {
  /* border: 1px solid blue; */
  height: 15px;
}

.wpwl-wrapper {
  width: 100%;
}

.wpwl-wrapper-brand {
  width: 65%;
}

.wpwl-brand-card {
  /* border: 1px solid red; */
  /* height: auto; */
}

.wpwl-wrapper .wpwl-control {
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.wpwl-group-expiry,
.wpwl-group-cvv {
  /* border: 1px solid red; */
  /* width: 50%; */
}

.wpwl-wrapper-submit {
  width: 100%;
  margin-top: 0.5em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  /* border: 1px solid red; */
}

.wpwl-wrapper-submit::before{
  display: none;
}

.customLabel{
  /* border: 1px solid orange; */
  /* height: auto; */
}

.customInput{
  /* border: 1px solid brown; */
  /* height: 2px; */
  margin-left: -1em;
  cursor: pointer;
}

.wpwl-button-pay {
  width: 100%;
  cursor: pointer;
  background-color: #7367f0 !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  color: #fff !important;
  font-weight: 500;
  vertical-align: middle;
  user-select: none;
  font-size: 1rem;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
  grid-column: 1 / span 2;
}

